
  import Vue from 'vue';

  export default Vue.extend({
    name: 'es-terms-and-conditions-referrals',
    metaInfo: {
      title: '',
      meta: [
        { name: 'robots', content: 'noindex' },
      ],
    },
  });
